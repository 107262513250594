import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';
import { parse, format, add } from 'date-fns';

import {
  CancelSubscriptionButton,
  EditButton,
  Hr,
  Text,
  WhiteBox,
} from '../../styles';
import { Col, Row } from '../../../../../styles/grid';
import { LoadingBox } from '../../../../../styles/common';
import { preto } from '../../../../../styles/colors';

import editImg from '../../../../../assets/images/clients/edit@2x.png';
import EditSubscriptionDataModal from '../../../../../components/EditSubscriptionDataModal';
import CancelSubscriptionModal from '../../../../../components/CancelSubscriptionModal';
import ManualConciliationModal from '../../../../../components/ManualConciliationModal';
import SubscriptionsTable from '../../../../../components/Tables/SubscriptionsTable';

import {
  getClientDataRequest,
  getSubscriptionsRequest,
  manualConciliationRequest,
  updateStoreSubscriptionDataRequest,
  generateNewPaymentLinkRequest,
} from '../../../../../store/modules/clients/actions';

import { amountMask } from '../../../../../utils/masks';
import {
  formatCurrencyBRL,
  onlyNumbersAmount,
} from '../../../../../utils/utils';
import px2vw from '../../../../../utils/responsiveness/px2vw';
import AccessValidate from '../../../../../utils/validations/AccessValidate';

export default function TabSubscription() {
  const { access } = useSelector((state) => state.user);
  const financialAccess = AccessValidate(
    access,
    'admin_financial',
    'financial',
    null,
    false
  );
  const commercialAccess = AccessValidate(
    access,
    'admin_commercial_edit',
    'commercial_edit',
    null,
    false
  );
  const {
    subscriptionsLoading,
    cancelSubscriptionSuccess,
    updateStoreSubscriptionSuccess,
    manualConciliationSuccess,
    removeCreditCardSuccess,
    newPaymentLinkLoading,
  } = useSelector((state) => state.clients);
  const subscriptions = useSelector((state) => state.clients?.subscriptions);
  const subscriptionsList = useSelector(
    (state) => state.clients?.subscriptionsList
  );
  const [newSubscriptionList, setNewSubscriptionList] = useState([]);
  const recurring = useSelector((state) => state.clients?.recurring);
  const clientData = useSelector((state) => state.clients?.clientData);

  const [editSubscriptionData, setEditSubscriptionData] = useState(false);
  const [cancelSubscription, setCancelSubscription] = useState(false);
  const [manualConciliation, setManualConciliation] = useState(false);
  const [isInFreeMonth, setIsInFreeMonth] = useState(false);
  const [startCurrPlanData, setStartCurrPlanData] = useState(null);
  const [
    finishPlanAfterFreeMonthData,
    setFinishPlanAfterFreeMonthData,
  ] = useState(null);
  const [orderedSubscriptions, setOrderedSubscriptions] = useState([]);
  const [recurringAmount, setRecurringAmount] = useState('');
  const [subscriptionAmount, setSubscriptionAmount] = useState('0');
  const [recurringName, setRecurringName] = useState('');
  const [subscriptionId, setSubscriptionId] = useState('');
  const [monthsWithKuppi, setMonthsWithKuppi] = useState('');
  const [
    hasPendingPaymentSubscription,
    setHasPendingPaymentSubscription,
  ] = useState(false);
  const [signatureUpdatePeriod, setSignatureUpdatePeriod] = useState('');
  const [paymentId, setPaymentId] = useState('');
  const [paymentType, setPaymentType] = useState('credit_card');
  const [manualConciliationSubscriptionId, setManualConcialiationSubscriptionId] = useState('');
  const [removeCreditCard, setRemoveCreditCard] = useState(false);
  const dispatch = useDispatch();

  function handleChangeSubscriptionSubmit() {
    if (
      subscriptionAmount === '' ||
      subscriptionId === '' ||
      (!signatureUpdatePeriod.includes('only_future') &&
        !signatureUpdatePeriod.includes('only_current') &&
        !signatureUpdatePeriod.includes('current_and_future'))
    ) {
      toast.error('Por gentileza não deixar nenhum campo em branco!');
    } else if (subscriptionAmount.length < 7) {
      toast.error(
        'Valor inválido. O valor deve conter 2 casas decimais, exemplo: R$ 123,90',
        {
          autoClose: 6000,
        }
      );
    } else {
      dispatch(
        updateStoreSubscriptionDataRequest(
          clientData.id,
          subscriptionId,
          onlyNumbersAmount(subscriptionAmount),
          signatureUpdatePeriod
        )
      );
    }
  }

  function handleManualConciliationSubmit() {
    if (paymentId === '') {
      toast.error('Por gentileza inserir o ID de pagamento da Pagar.Me!');
    } else {
      dispatch(
        manualConciliationRequest(
          clientData.id,
          manualConciliationSubscriptionId,
          paymentId,
          paymentType
        )
      );
    }
  }

  useEffect(() => {
    if (subscriptions?.length > 0) {
      setIsInFreeMonth(
        subscriptions[0].payment_type === 'free' && subscriptions.length === 1
      );

      // Sort the advertiser subscriptions in order to show at history
      const advSubsOrdered = [...subscriptions];
      advSubsOrdered.sort(function sort(a, b) {
        return b.id - a.id;
      });
      setOrderedSubscriptions(advSubsOrdered);
    }
  }, [subscriptions]);

  useEffect(() => {
    setHasPendingPaymentSubscription(
      orderedSubscriptions?.length > 0 &&
        orderedSubscriptions[0]?.status === 'not_paid'
    );
  }, [orderedSubscriptions]);

  useEffect(() => {
    if (recurring && !subscriptionsLoading) {
      if (recurring?.next_recurring_at) {
        setStartCurrPlanData(
          format(
            add(parse(recurring?.next_recurring_at, 'dd/MM/yyyy', new Date()), {
              months: recurring?.subscription?.period * -1,
            }),
            'dd/MM/yyyy'
          )
        );
  
        setFinishPlanAfterFreeMonthData(
          format(
            add(parse(recurring?.next_recurring_at, 'dd/MM/yyyy', new Date()), {
              months: recurring?.subscription?.period,
            }),
            'dd/MM/yyyy'
          )
        );
      } else {
        setStartCurrPlanData(
          format(
            add(new Date(), {
              months: recurring?.subscription?.period * -1,
            }),
            'dd/MM/yyyy'
          )
        );
  
        setFinishPlanAfterFreeMonthData(
          format(
            add(new Date(), {
              months: recurring?.subscription?.period,
            }),
            'dd/MM/yyyy'
          )
        );
      }

      setSubscriptionAmount(amountMask(recurring?.amount?.toFixed(2)));
      setSubscriptionId(recurring?.subscription_id);
    }
  }, [recurring]);

  useEffect(() => {
    if (recurring && !subscriptionsLoading) {
      setRecurringAmount(formatCurrencyBRL(recurring?.amount?.toFixed(2), {}));
      setRecurringName(recurring?.subscription?.name);
      setMonthsWithKuppi(clientData?.store_data?.months_with_kuppi);
    }
  }, [recurring, clientData]);

  useEffect(() => {
    if (subscriptionsList?.length > 0) {
      setNewSubscriptionList(
        subscriptionsList?.map((subscription) => {
          return {
            id: subscription?.id,
            title: subscription?.name,
          };
        })
      );
    }
  }, [subscriptionsList]);

  useEffect(() => {
    if (cancelSubscriptionSuccess) {
      setCancelSubscription(false);
      dispatch(getClientDataRequest(clientData?.id));
      dispatch(getSubscriptionsRequest(clientData?.id));
    }
  }, [cancelSubscriptionSuccess]);

  useEffect(() => {
    if (updateStoreSubscriptionSuccess) {
      setEditSubscriptionData(false);
      dispatch(getSubscriptionsRequest(clientData?.id));
    }
  }, [updateStoreSubscriptionSuccess]);

  useEffect(() => {
    if(manualConciliationSuccess) {
      setManualConciliation(false)
      dispatch(getSubscriptionsRequest(clientData?.id));
    }
  },[manualConciliationSuccess])

  useEffect(() => {
    if(removeCreditCardSuccess) {
      setRemoveCreditCard(false);
      setEditSubscriptionData(false);
      dispatch(getSubscriptionsRequest(clientData?.id));
    }
  },[removeCreditCardSuccess])

  const handler = {
    advertiserInfoId: clientData?.id,
    subscriptionAmount: subscriptionAmount,
    editSubscriptionData: editSubscriptionData,
    cancelSubscription: cancelSubscription,
    signatureUpdatePeriod: signatureUpdatePeriod,
    manualConciliation: manualConciliation,
    subscriptionId: subscriptionId,
    newSubscriptionList: newSubscriptionList,
    monthsWithKuppi: monthsWithKuppi,
    hasPendingPaymentSubscription: hasPendingPaymentSubscription,
    paymentId: paymentId,
    manualConciliationSubscriptionId: manualConciliationSubscriptionId,
    removeCreditCard: removeCreditCard,
    setEditSubscriptionData: () => setEditSubscriptionData(),
    setCancelSubscription: () => setCancelSubscription(),
    setManualConciliation: () => setManualConciliation(!manualConciliation),
    setSubscriptionAmount: (val) => setSubscriptionAmount(val),
    setSubscriptionId: (e) => setSubscriptionId(e),
    setSignatureUpdatePeriod: (val) => setSignatureUpdatePeriod(val),
    handleChangeSubscriptionSubmit: () => handleChangeSubscriptionSubmit(),
    setPaymentId: (e) => setPaymentId(e),
    handleManualConciliationSubmit: () => handleManualConciliationSubmit(),
    setManualConcialiationSubscriptionId: (subscriptionId) => setManualConcialiationSubscriptionId(subscriptionId),
    setRemoveCreditCard: () => setRemoveCreditCard(!removeCreditCard),
  };

  return (
    <>
      {subscriptionsLoading ? (
        <LoadingBox>
          <Spinner
            style={{
              width: '1.2rem',
              height: '1.2rem',
              color: preto,
            }}
          />{' '}
        </LoadingBox>
      ) : (
        <Col>
          <Row justifyStart alignCenter>
            <Text fontweight="bold" fontsize={px2vw(13)} margright={px2vw(15)}>
              Dados do plano atual
            </Text>
            {(financialAccess || commercialAccess) && (
              <EditButton onClick={() => setEditSubscriptionData(true)}>
                <img
                  src={editImg}
                  alt="Edit Icon"
                  width={px2vw(175)}
                  height={px2vw(175)}
                />{' '}
                Editar
              </EditButton>
            )}
          </Row>

          <WhiteBox margtop={px2vw(10)}>
            <Row spaceBetween margbottom={px2vw(10)} alignCenter>
              <Col width="auto">
                <Text fontweight="bold" fontsize={px2vw(13)}>
                  Plano
                </Text>
                <Text>{recurringName}</Text>
              </Col>
              <Col width="auto">
                <Text fontweight="bold" fontsize={px2vw(13)}>
                  Início
                </Text>
                <Text>
                  {!isInFreeMonth &&
                    orderedSubscriptions[0] &&
                    orderedSubscriptions[0]?.start_validity_at}
                  {isInFreeMonth && recurring?.next_recurring_at}
                </Text>
              </Col>
              <Col width="auto">
                <Text fontweight="bold" fontsize={px2vw(13)}>
                  Válido até
                </Text>
                <Text>
                  {!isInFreeMonth &&
                    orderedSubscriptions[0] &&
                    orderedSubscriptions[0]?.finish_validity_at}
                  {isInFreeMonth && finishPlanAfterFreeMonthData}
                </Text>
              </Col>
              <Col width="auto">
                {financialAccess &&
                  clientData?.store_data?.status?.status !== 'Cancelado' && (
                    <CancelSubscriptionButton
                      onClick={() => setCancelSubscription(true)}
                    >
                      Cancelar Assinatura
                    </CancelSubscriptionButton>
                  )}
                {financialAccess && clientData?.store_data?.status?.status === 'Cancelado' &&
                  !hasPendingPaymentSubscription && (
                    <CancelSubscriptionButton
                      onClick={() => dispatch(generateNewPaymentLinkRequest(clientData?.id))}
                      disabled={newPaymentLinkLoading}
                    >
                      {newPaymentLinkLoading ? (
                          <LoadingBox>
                            <Spinner
                              style={{
                                width: '1.2rem',
                                height: '1.2rem',
                                color: preto,
                              }}
                            />{' '}
                          </LoadingBox>
                        ) : (
                          'Gerar link de pagamento'
                        )}
                      
                    </CancelSubscriptionButton>
                  )}
              </Col>
            </Row>
            <Hr />
            <Row spaceBetween margtop={px2vw(10)} alignCenter>
              <Col width="auto">
                <Text fontweight="bold" fontsize={px2vw(13)}>
                  Último pagamento
                </Text>
                <Text>
                  {!isInFreeMonth && (recurring?.last_payment_at ? recurring?.last_payment_at : '-')}
                  {isInFreeMonth && '-'}
                </Text>
              </Col>
              <Col width="auto">
                <Text fontweight="bold" fontsize={px2vw(13)}>
                  Valor
                </Text>
                <Text>{recurringAmount}</Text>
              </Col>
              <Col width="auto">
                <Text fontweight="bold" fontsize={px2vw(13)}>
                  Forma de pagamento
                </Text>
                <Text>
                  {recurring?.payment_type === 'pix' && 'PIX'}
                  {recurring?.payment_type === 'boleto' && 'Boleto'}
                  {recurring?.payment_type === 'credit_card' &&
                    `Cartão de Crédito ${
                      recurring?.paymentCard &&
                      recurring?.paymentCard.brand.toUpperCase()
                    } ****${
                      recurring?.paymentCard && recurring?.paymentCard?.last_digits
                    }`}
                  {!recurring?.payment_type && '-'}
                </Text>
              </Col>
              <Col width="auto"></Col>
            </Row>
          </WhiteBox>

          <Row justifyStart alignCenter margtop={px2vw(10)}>
            <Text fontweight="bold" fontsize={px2vw(13)} margright={px2vw(5)}>
              Faturas
            </Text>
          </Row>

          {!subscriptionsLoading && (
            <WhiteBox>
              <SubscriptionsTable
                data={orderedSubscriptions}
                handler={handler}
              />
            </WhiteBox>
          )}
        </Col>
      )}
      {editSubscriptionData && <EditSubscriptionDataModal handler={handler} />}
      {cancelSubscription && <CancelSubscriptionModal handler={handler} />}
      {manualConciliation && <ManualConciliationModal handler={handler} />}
    </>
  );
}
